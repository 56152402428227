<template>
    <MailPopup :lang="lang" @handleMailClick="handleMailEvent"/>
</template>

<script>
import data from '@/locale';
import MailPopup from '@/components/MailPopup.vue';

export default {
    name: 'MailPage',
    components: {
        MailPopup
    },
    created() {
        this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale
    },
    methods: {
        handleMailEvent() {
            this.$emit('onMail');
        }
    },
    data() {
        return {
            locale: data.data,
            lang: "en",
            supportedLangs: ["en", "cz", "hu"],
        }
    }
}
</script>

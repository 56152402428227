
export default { 
    data: {
        title_q1: {
            en: "What do you like to eat?", 
            cz: "Co rádi jíte?",
            hu: "Mit szeretsz enni?"
        },
        description_q1: {
            en: "Choose from a pre-set diet. You can fine-tune the excluded foods later.", 
            cz: "Vyberte si z přednastavené stravy. Vyloučené potraviny můžete později doladit.",
            hu: "Válassz egy előre beállított diétát. Később finomhangolhatod a kizárt ételeket."
        },
        tiple_q1: {
            en: "Don't see your preferred diet? Choose \"Anything\" and then customize it later.",
            cz: "Nevidíte svou preferovanou stravu? Vyberte možnost „Cokoli“, později ji můžete upravit.",
            hu: "Nem látod a preferált étrendedet? Válaszd az \"Bármi\" opciót, és később testreszabhatod."
        },
        a1_q1: {
            en: "Anything",
            cz: "Cokoli",
            hu: "Bármi"
        },
        l1_q1: {
            en: "Excludes: Nothing",
            cz: "Vyloučeno: nic",
            hu: "Kizárva: Semmi"
        },
        a2_q1: {
            en: "Keto",
            cz: "Keto",
            hu: "Keto"
        },
        l2_q1: {
            en: "Excludes: Grains, Legumes, Starchy Vegetables",
            cz: "Vyloučeno: obiloviny, luštěniny, škrobová zelenina",
            hu: "Kizárva: Gabonák, Hüvelyesek, Keményítőtartalmú zöldségek"
        },
        a3_q1: {
            en: "Mediterranean",
            cz: "Středomořská",
            hu: "Mediterrán"
        },
        l3_q1: {
            en: "Excludes: Red Meat, Fruit juice, Starchy Vegetables",
            cz: "Vyloučeno: červené maso, ovocné šťávy, škrobová zelenina",
            hu: "Kizárva: Vörös húsok, Gyümölcslevek, Keményítőtartalmú zöldségek"
        },
        a4_q1: {
            en: "Paleo",
            cz: "Paleo",
            hu: "Paleo"
        },
        l4_q1: {
            en: "Excludes: Dairy, Grains, Legumes, Soy, Starchy Vegetables",
            cz: "Vyloučeno: mléčné výrobky, obiloviny, luštěniny, sója, škrobová zelenina",
            hu: "Kizárva: Tejtermékek, Gabonák, Hüvelyesek, Szója, Keményítőtartalmú zöldségek"
        },
        a5_q1: {
            en: "Vegan",
            cz: "Veganská",
            hu: "Vegán"
        },
        l5_q1: {
            en: "Excludes: Red Meat, Poultry, Fish, Shellfish, Dairy, Eggs, Mayo, Honey",
            cz: "Vyloučeno: červené maso, drůbež, ryby, měkkýši, mléčné výrobky, vejce, majonéza, med",
            hu: "Kizárva: Vörös húsok, Baromfi, Halak, Tengeri herkentyűk, Tejtermékek, Tojás, Majonéz, Méz"
        },
        a6_q1: {
            en: "Vegetarian",
            cz: "Vegetariánská",
            hu: "Vegetáriánus"
        },
        l6_q1: {
            en: "Excludes: Red Meat, Poultry, Fish, Shellfish",
            cz: "Vyloučeno: červené maso, drůbež, ryby, měkkýši",
            hu: "Kizárva: Vörös húsok, Baromfi, Halak, Tengeri herkentyűk"
        },
        cbutton: {
            en: "Continue",
            cz: "Pokračovat",
            hu: "Folytatás"
        },
        title_q2: {
            en: "Are there foods you avoid?",
            cz: "Vyhýbáte se některým potravinám?",
            hu: "Vannak olyan ételek, amelyeket elkerülsz?"
        },
        description_q2: {
            en: "This may be due to allergies or any other reason.",
            cz: "Může to být kvůli alergiím nebo z jiných důvodů.",
            hu: "Ez lehet allergia vagy bármilyen más ok miatt."
        },
        common_q2: {
            en: "Common Exclusions",
            cz: "Běžně vylučované potraviny",
            hu: "Gyakori kizárások"
        },
        o1_q2: {
            en: "Gluten",
            cz: "Lepek",
            hu: "Glutén"
        },
        o2_q2: {
            en: "Peanuts",
            cz: "Arašídy",
            hu: "Földimogyoró"
        },
        o3_q2: {
            en: "Eggs",
            cz: "Vejce",
            hu: "Tojás"
        },
        o4_q2: {
            en: "Fish",
            cz: "Ryby",
            hu: "Hal"
        },
        o5_q2: {
            en: "Tree Nuts",
            cz: "Stromové ořechy",
            hu: "Diófélék"
        },
        o6_q2: {
            en: "Dairy",
            cz: "Mléčné výrobky",
            hu: "Tejtermékek"
        },
        o7_q2: {
            en: "Soy",
            cz: "Sója",
            hu: "Szója"
        },
        o8_q2: {
            en: "Shellfish",
            cz: "Měkkýši",
            hu: "Tengeri herkentyűk"
        },
        other_q2: {
            en: "Other Exclusions",
            cz: "Další vylučované potraviny",
            hu: "Egyéb kizárások"
        },
        c1_o1_q2: {
            en: "Dairy",
            cz: "Mléčné výrobky",
            hu: "Tejtermékek"
        },
        c1_o2_q2: {
            en: "Milk",
            cz: "Mléko",
            hu: "Tej"
        },
        c1_o3_q2: {
            en: "Cream",
            cz: "Smetana",
            hu: "Tejszín"
        },
        c1_o4_q2: {
            en: "Cheese",
            cz: "Sýr",
            hu: "Sajt"
        },
        c1_o5_q2: {
            en: "Yogurt",
            cz: "Jogurt",
            hu: "Joghurt"
        },
        c1_o6_q2: {
            en: "Cottage Cheese",
            cz: "Tvaroh",
            hu: "Túró"
        },
        c1_o7_q2: {
            en: "Whey Powder",
            cz: "Syrovátkový prášek",
            hu: "Tejsavópor"
        },
        c2_o1_q2: {
            en: "Eggs",
            cz: "Vejce",
            hu: "Tojás"
        },
        c3_o1_q2: {
            en: "Grains",
            cz: "Obiloviny",
            hu: "Gabonák"
        },
        c3_o2_q2: {
            en: "Breakfast Cereals",
            cz: "Snídaňové cereálie",
            hu: "Reggeli gabonapelyhek"
        },
        c3_o3_q2: {
            en: "Pastas",
            cz: "Těstoviny",
            hu: "Tészták"
        },
        c3_o4_q2: {
            en: "Breads",
            cz: "Pečivo",
            hu: "Kenyerek"
        },
        c3_o5_q2: {
            en: "Rice",
            cz: "Rýže",
            hu: "Rizs"
        },
        c3_o6_q2: {
            en: "Oatmeal",
            cz: "Ovesné vločky",
            hu: "Zabpehely"
        },
        c3_o7_q2: {
            en: "Sugar",
            cz: "Cukr",
            hu: "Cukor"
        },
        c4_o1_q2: {
            en: "Soy",
            cz: "Sója",
            hu: "Szója"
        },
        c4_o2_q2: {
            en: "Tofu",
            cz: "Tofu",
            hu: "Tofu"
        },
        c4_o3_q2: {
            en: "Soy Milk",
            cz: "Sójové mléko",
            hu: "Szójatej"
        },
        c5_o1_q2: {
            en: "Red Meat",
            cz: "Červené maso",
            hu: "Vörös hús"
        },
        c5_o2_q2: {
            en: "Beef",
            cz: "Hovězí",
            hu: "Marhahús"
        },
        c5_o3_q2: {
            en: "Pork/Bacon",
            cz: "Vepřové/slanina",
            hu: "Sertéshús/Szalonna"
        },
        c5_o4_q2: {
            en: "Lamb",
            cz: "Jehněčí",
            hu: "Bárányhús"
        },
        c5_o5_q2: {
            en: "Veal",
            cz: "Telecí",
            hu: "Borjúhús"
        },
        c6_o1_q2: {
            en: "Poultry",
            cz: "Drůbež",
            hu: "Baromfi"
        },
        c6_o2_q2: {
            en: "Chicken",
            cz: "Kuře",
            hu: "Csirke"
        },
        c6_o3_q2: {
            en: "Turkey",
            cz: "Krůta",
            hu: "Pulyka"
        },
        c7_o1_q2: {
            en: "Fish",
            cz: "Ryby",
            hu: "Hal"
        },
        c7_o2_q2: {
            en: "Salmon",
            cz: "Losos",
            hu: "Lazac"
        },
        c7_o3_q2: {
            en: "Tuna",
            cz: "Tuňák",
            hu: "Tonhal"
        },
        c7_o4_q2: {
            en: "Tilapia",
            cz: "Tilapie",
            hu: "Tilápia"
        },
        c7_o5_q2: {
            en: "Sardines",
            cz: "Sardinky",
            hu: "Szardínia"
        },
        c7_o6_q2: {
            en: "Trout & Snapper",
            cz: "Pstruh a chňapal",
            hu: "Pisztráng & Sügér"
        },
        c8_o1_q2: {
            en: "Shellfish",
            cz: "Měkkýši",
            hu: "Tengeri herkentyűk"
        },
        c9_o1_q2: {
            en: "Mayo",
            cz: "Majonéza",
            hu: "Majonéz"
        },
        c10_o1_q2: {
            en: "Fats & Nuts",
            cz: "Tuky a ořechy",
            hu: "Zsírok & Diófélék"
        },
        c10_o2_q2: {
            en: "Avocado",
            cz: "Avokádo",
            hu: "Avokádó"
        },
        c10_o3_q2: {
            en: "Peanuts",
            cz: "Arašídy",
            hu: "Földimogyoró"
        },
        c10_o4_q2: {
            en: "Almonds",
            cz: "Mandle",
            hu: "Mandula"
        },
        c10_o5_q2: {
            en: "Walnuts",
            cz: "Vlašské ořechy",
            hu: "Dió"
        },
        c10_o6_q2: {
            en: "Pecans",
            cz: "Pekanové ořechy",
            hu: "Pekándió"
        },
        c11_o1_q2: {
            en: "Legumes",
            cz: "Luštěniny",
            hu: "Hüvelyesek"
        },
        c11_o2_q2: {
            en: "Beans",
            cz: "Fazole",
            hu: "Bab"
        },
        c11_o3_q2: {
            en: "Lentils",
            cz: "Čočka",
            hu: "Lencse"
        },
        c11_o4_q2: {
            en: "Peas",
            cz: "Hrách",
            hu: "Borsó"
        },
        c12_o1_q2: {
            en: "Fruit",
            cz: "Ovoce",
            hu: "Gyümölcs"
        },
        c12_o2_q2: {
            en: "Apple",
            cz: "Jablko",
            hu: "Alma"
        },
        c12_o3_q2: {
            en: "Banana",
            cz: "Banán",
            hu: "Banán"
        },
        c12_o4_q2: {
            en: "Grapes",
            cz: "Hrozny",
            hu: "Szőlő"
        },
        c12_o5_q2: {
            en: "Orange",
            cz: "Pomeranč",
            hu: "Narancs"
        },
        c12_o6_q2: {
            en: "Strawberries",
            cz: "Jahody",
            hu: "Eper"
        },
        c12_o7_q2: {
            en: "Raspberries",
            cz: "Maliny",
            hu: "Málna"
        },
        c12_o8_q2: {
            en: "Blueberries",
            cz: "Borůvky",
            hu: "Áfonya"
        },
        c12_o9_q2: {
            en: "Fruit juice",
            cz: "Ovocná šťáva",
            hu: "Gyümölcslé"
        },
        c13_o1_q2: {
            en: "Vegetables",
            cz: "Zelenina",
            hu: "Zöldségek"
        },
        c13_o2_q2: {
            en: "Artichoke",
            cz: "Artyčok",
            hu: "Articsóka"
        },
        c13_o3_q2: {
            en: "Asparagus",
            cz: "Chřest",
            hu: "Spárga"
        },
        c13_o4_q2: {
            en: "Beets",
            cz: "Červená řepa",
            hu: "Cékla"
        },
        c13_o5_q2: {
            en: "Broccoli",
            cz: "Brokolice",
            hu: "Brokkoli"
        },
        c13_o6_q2: {
            en: "Carrots",
            cz: "Mrkev",
            hu: "Répa"
        },
        c13_o7_q2: {
            en: "Sprouts",
            cz: "Klíčky",
            hu: "Csírák"
        },
        c13_o8_q2: {
            en: "Celery",
            cz: "Celer",
            hu: "Zeller"
        },
        c13_o9_q2: {
            en: "Peppers",
            cz: "Papriky",
            hu: "Paprika"
        },
        c13_o10_q2: {
            en: "Tomato",
            cz: "Rajče",
            hu: "Paradicsom"
        },
        c13_o11_q2: {
            en: "Eggplant",
            cz: "Lilek",
            hu: "Padlizsán"
        },
        c14_o1_q2: {
            en: "Starchy Vegetables",
            cz: "Škrobová zelenina",
            hu: "Keményítőtartalmú zöldségek"
        },
        c14_o2_q2: {
            en: "Potatoes & Yams",
            cz: "Brambory a batáty",
            hu: "Burgonya és Jamszgyökér"
        },
        c14_o3_q2: {
            en: "Corn",
            cz: "Kukuřice",
            hu: "Kukorica"
        },
        c15_o1_q2: {
            en: "Honey",
            cz: "Med",
            hu: "Méz"
        },
        title_q3: {
            en: "How do you measure things?",
            cz: "Jakým způsobem věci měříte?",
            hu: "Hogyan méred a dolgokat?"
        },
        description_q3: {
            en: "We will use this to set goals and display amounts in recipes.",
            cz: "Použijeme to pro nastavení cílů a zobrazování množství v receptech.",
            hu: "Ezt fogjuk használni a célok beállításához és a receptekben lévő mennyiségek megjelenítéséhez."
        },
        units_q3: {
            en: "Preferred units",
            cz: "Preferované jednotky",
            hu: "Preferált mértékegységek"
        },
        energy_q3: {
            en: "Preferred unit of energy",
            cz: "Preferovaná jednotka energie",
            hu: "Preferált energia mértékegység"
        },
        title_q4: {
            en: "What is your goal?",
            cz: "Jaký je váš cíl?",
            hu: "Mi a célod?"
        },
        description_q4: {
            en: "This information lets us suggest meals to help you reach your goal.",
            cz: "Tyto informace nám umožňují navrhovat jídla, která vám pomohou dosáhnout vašeho cíle.",
            hu: "Ez az információ lehetővé teszi számunkra, hogy olyan ételeket javasoljunk, amelyek segítenek elérni a célodat."
        },
        goal_q4: {
            en: "Set a goal",
            cz: "Nastavit cíl",
            hu: "Cél beállítása"
        },
        m_goal_q4: {
            en: "General Goal",
            cz: "Obecný cíl",
            hu: "Általános cél"
        },
        e_goal_q4: {
            en: "Exact Goal",
            cz: "Přesný cíl",
            hu: "Pontos cél"
        },
        want_q4: {
            en: "I want to",
            cz: "Chci",
            hu: "Szeretnék"
        },
        lose_q4: {
            en: "Lose fat",
            cz: "Zhubnout",
            hu: "Zsírt veszíteni"
        },
        maintain_q4: {
            en: "Maintain weight",
            cz: "Udržet váhu",
            hu: "Megtartani a súlyt"
        },
        muscle_q4: {
            en: "Build muscle",
            cz: "Nabrat svaly",
            hu: "Izmot építeni"
        },
        w_goal_q4: {
            en: "Your weight goal",
            cz: "Vaše cílová váha",
            hu: "Célzott testsúlyod"
        },
        w_change_q4: {
            en: "Target weight change",
            cz: "Cílová změna váhy",
            hu: "Célzott testsúlyváltozás"
        },
        kg_warn_q4: {
            en: "The CDC and health professionals strongly advise against weight changes of more than 0.9 kg a week.",
            cz: "CDC a zdravotní profesionálové rozhodně nedoporučují změny váhy o více než 0,9 kg za týden.",
            hu: "A CDC és az egészségügyi szakemberek határozottan ellenzik a heti 0,9 kg-nál nagyobb testsúlyváltozást."
        },
        lbs_warn_q4: {
            en: "The CDC and health professionals strongly advise against weight changes of more than 2 lbs a week.",
            cz: "CDC a zdravotní profesionálové důrazně nedoporučují změny váhy o více než 2 libry za týden.",
            hu: "A CDC és az egészségügyi szakemberek határozottan ellenzik a heti 2 fontnál nagyobb testsúlyváltozást."
        },
        week_q4: {
            en: "kg a week",
            cz: "kg za týden",
            hu: "kg hetente"
        },
        week_lbs_q4: {
            en: "lbs a week",
            cz: "libry za týden",
            hu: "font hetente"
        },
        title_q5: {
            en: "Tell us about yourself",
            cz: "Řekněte nám něco o sobě",
            hu: "Mesélj magadról"
        },
        description_q5: {
            en: "This information lets us estimate your nutrition requirements for each day.",
            cz: "Tyto informace nám umožňují odhadnout vaše denní nutriční potřeby.",
            hu: "Ezek az információk segítenek megbecsülni a napi tápanyagszükségletedet."
        },
        sex_q5: {
            en: "Sex",
            cz: "Pohlaví",
            hu: "Nem"
        },
        male_q5: {
            en: "Male",
            cz: "Muž",
            hu: "Férfi"
        },
        female_q5: {
            en: "Female",
            cz: "Žena",
            hu: "Nő"
        },
        binary_q5: {
            en: "Non-Binary",
            cz: "Nebinární",
            hu: "Nem bináris"
        },
        height_q5: {
            en: "Height",
            cz: "Výška",
            hu: "Magasság"
        },
        weight_q5: {
            en: "Weight",
            cz: "Váha",
            hu: "Testsúly"
        },
        age_q5: {
            en: "Age",
            cz: "Věk",
            hu: "Életkor"
        },
        activity_q5: {
            en: "Activity Level",
            cz: "Úroveň aktivity",
            hu: "Aktivitási szint"
        },
        jobe_q5: {
            en: "Desk jobe, light exercise",
            cz: "Práce v kanceláři, lehké cvičení",
            hu: "Ülőmunka, könnyű testmozgás"
        },
        workout_q5: {
            en: "Lightly active, workout 3-4 times/week",
            cz: "Lehce aktivní, cvičení 3-4 krát týdně",
            hu: "Kissé aktív, edzés heti 3-4 alkalommal"
        },
        daily_q5: {
            en: "Active daily, frequent exercise",
            cz: "Každodenní aktivita, časté cvičení",
            hu: "Napi szinten aktív, gyakori testmozgás"
        },
        very_q5: {
            en: "Very Athletic",
            cz: "Velmi sportovně založený",
            hu: "Nagyon sportos"
        },
        extrime_q5: {
            en: "Extremely Athletic",
            cz: "Extrémně sportovně založený",
            hu: "Rendkívül sportos"
        },
        bodyfat_q5: {
            en: "Bodyfat",
            cz: "Tělesný tuk",
            hu: "Testzsír"
        },
        low_q5: {
            en: "Low",
            cz: "Nízký",
            hu: "Alacsony"
        },
        medium_q5: {
            en: "Medium",
            cz: "Střední",
            hu: "Közepes"
        },
        high_q5: {
            en: "High",
            cz: "Vysoký",
            hu: "Magas"
        },
        title_q6: {
            en: "Set Nutrition Target",
            cz: "Nastavte nutriční cíle",
            hu: "Állítsd be a táplálkozási célt"
        },
        description_q6: {
            en: "Take a look at the daily nutrition targets we've estimated for you based on your profile. You can adjust the targets by clicking the edit button or do it later on your meal planner page.",
            cz: "Podívejte se na denní nutriční cíle, které jsme pro vás odhadli na základě vašeho profilu. Cíle můžete upravit kliknutím na tlačítko Upravit nebo to udělat později na stránce plánovače jídel.",
            hu: "Nézd meg a napi táplálkozási célokat, amelyeket a profilod alapján becsültünk neked. A célokat módosíthatod a szerkesztés gombra kattintva, vagy később az étkezési tervező oldaladon."
        },
        subtitle_q6: {
            en: "My Nutrition Targets",
            cz: "Moje nutriční cíle",
            hu: "Táplálkozási céljaim"
        },
        edit_q6: {
            en: "Edit",
            cz: "Upravit",
            hu: "Szerkesztés"
        },
        per_q6: {
            en: "Calories per day",
            cz: "kalorií za den",
            hu: "Kalória naponta"
        },
        least_q6: {
            en: "At least",
            cz: "Alespoň",
            hu: "Legalább"
        },
        carb_q6: {
            en: "Carbs",
            cz: "Sacharidů",
            hu: "Szénhidrátok"
        },
        fat_q6: {
            en: "Fats",
            cz: "Tuků",
            hu: "Zsírok"
        },
        protein_q6: {
            en: "Proteins",
            cz: "Bílkovin",
            hu: "Fehérjék"
        },
        suggest_q6: {
            en: "Suggested Nutrition Targets",
            cz: "Navrhované nutriční cíle",
            hu: "Javasolt táplálkozási célok"
        },
        sure_q6: {
            en: "Not sure?",
            cz: "Nejste si jistí?",
            hu: "Nem vagy biztos benne?"
        },
        view_q6: {
            en: "View Our Suggestions",
            cz: "Zobrazit naše návrhy",
            hu: "Nézd meg javaslatainkat"
        },
        hint_q6: {
            en: "Title",
            cz: "Název",
            hu: "Cím"
        },
        calories_q6: {
            en: "Calories",
            cz: "Kalorie",
            hu: "Kalória"
        },
        accept_q6: {
            en: "Accept",
            cz: "Přijmout",
            hu: "Elfogadás"
        },
        macros_q6: {
            en: "Target Macros",
            cz: "Cílové makroživiny",
            hu: "Cél makrotápanyagok"
        },
        select_q6: {
            en: "Select the range of each macronutrient you want in your diet. A more flexible range will give you much more variety in your meals.",
            cz: "Vyberte rozsah každé makroživiny, kterou chcete ve své stravě mít. Flexibilnější rozsah vám poskytne větší rozmanitost v jídlech.",
            hu: "Válaszd ki az egyes makrotápanyagok tartományát, amelyeket az étrendedben szeretnél. A rugalmasabb tartomány nagyobb változatosságot biztosít az ételeidben."
        },
        micro_q6: {
            en: "Micronutrients",
            cz: "Mikroživiny",
            hu: "Mikrotápanyagok"
        },
        mf_q6: {
            en: "Minimum Fiber",
            cz: "Minimum vlákniny",
            hu: "Minimális rost"
        },
        ld_q6: {
            en: "Limit Daily Sodium",
            cz: "Omezit denní příjem sodíku",
            hu: "Napi nátriumbevitel korlátozása"
        },
        ms_q6: {
            en: "Maximum Sodium",
            cz: "Maximální příjem sodíku",
            hu: "Maximális nátrium"
        },
        dc_q6: {
            en: "Limit Daily Cholesterol",
            cz: "Omezit denní příjem cholesterolu",
            hu: "Napi koleszterinbevitel korlátozása"
        },
        mc_q6: {
            en: "Maximum Cholesterol",
            cz: "Maximální příjem cholesterolu",
            hu: "Maximális koleszterin"
        },
        enter_mail: {
            en: "Enter your email to learn how to create a bio-individual lifestyle",
            cz: "Zadejte svůj e-mail a zjistěte, jak vytvořit bio-individuální životní styl",
            hu: "Add meg az email címed, hogy megtudd, hogyan hozhatsz létre bio-individuális életmódot"
        },
        confirm: {
            en: "Confirm",
            cz: "Potvrdit",
            hu: "Megerősítés"
        },
        bodyfat: {
            en: "Body Fat",
            cz: "Tělesný tuk",
            hu: "Testzsír"
        },
        offer_ends: {
            en: "This offer ends in",
            cz: "Tato nabídka končí v",
            hu: "Az ajánlat véget ér"
        },
        get_plan: {
            en: "Get my plan",
            cz: "Získejte můj plán",
            hu: "Szerezd meg a tervemet"
        },
        result_typical: {
            en: "Results are not typical. Individual results may vary.",
            cz: "Výsledky nejsou typické. Jednotlivé výsledky se mohou lišit.",
            hu: "Az eredmények nem tipikusak. Az egyéni eredmények eltérőek lehetnek."
        },
        choose: {
            en: "Choose your plan",
            cz: "Vyberte si svůj plán",
            hu: "Válaszd ki a tervedet"
        },
        per: {
            en: "per day",
            cz: "za den",
            hu: "naponta"
        },
        achieve: {
            en: "The best plan to",
            cz: "Nejlepší plán k",
            hu: "A legjobb terv a"
        },
        goal: {
            en: "achieve your goal",
            cz: "dosažení vašeho cíle",
            hu: "célod eléréséhez"
        },
        success: {
            en: "Success stories from our users",
            cz: "Příběhy úspěšných našich uživatelů",
            hu: "Sikertörténetek felhasználóinktól"
        },
        users_goal: {
            en: "Our users meet their goals",
            cz: "Naši uživatelé plní své cíle",
            hu: "Felhasználóink elérik céljaikat"
        },
        review_one: {
            en: "PerfectPlate has been a game-changer for me! I struggled with losing weight for years, trying different diets that always ended in frustration. With PerfectPlate, I finally found a comfortable and sustainable way to shed those extra pounds. The personalized meal plans fit my preferences perfectly, and I haven’t experienced any of the usual cravings or diet fatigue. I’ve lost 15 pounds in three months and feel better than ever!",
            cz: "PerfectPlate byl pro mě zlomem! Léta jsem bojovala s hubnutím a zkoušela různé diety, které ale nakonec skončily vždycky frustrací. S PerfectPlate jsem konečně našla pohodlný a udržitelný způsob, jak se těch nadbytečných kil zbavit. Personalizované plány jídel přesně odpovídají mým preferencím a během diety jsem nezaznamenala žádné obvyklé chutě nebo únavu. Za tři měsíce jsem zhubla 7,5 kilo a cítím se lépe než dřív!",
            hu: "A PerfectPlate igazi áttörés volt számomra! Évekig küzdöttem a fogyással, különböző diétákat próbáltam, amelyek mindig csalódással végződtek. A PerfectPlate segítségével végre megtaláltam a kényelmes és fenntartható módját annak, hogy megszabaduljak a felesleges kilóktól. A személyre szabott étkezési tervek tökéletesen illeszkednek az ízlésemhez, és nem tapasztaltam a szokásos sóvárgást vagy diétás fáradtságot. Három hónap alatt 7 kg-ot fogytam, és jobban érzem magam, mint valaha!"
        },
        review_two: {
            en: "As someone who wanted to gain muscle mass, I always found it challenging to eat the right amount of calories and nutrients. PerfectPlate made it so easy. The service calculated my exact needs and provided a tailored meal plan that supported my workouts. In just two months, I’ve gained 10 pounds of muscle and my energy levels have skyrocketed. PerfectPlate took the guesswork out of my diet and helped me achieve my goals efficiently.",
            cz: "Jelikož jsem chtěl nabrat svalovou hmotu, měl jsem vždy problém jíst správné množství kalorií a živin. PerfectPlate mi to usnadnil. Služba vypočítala mé přesné potřeby a poskytla mi na míru šitý jídelní plán, který podporoval mé tréninky. Během dvou měsíců jsem nabral 5 kilo svalů a hladina energie se mi výrazně zvýšila. PerfectPlate mi pomohl odstranit nejistotu ohledně stravování a efektivně dosáhnout svých cílů.",
            hu: "Mint aki izomtömeget akart növelni, mindig kihívást jelentett a megfelelő mennyiségű kalória és tápanyag bevitele. A PerfectPlate ezt nagyon egyszerűvé tette. A szolgáltatás kiszámította a pontos szükségleteimet, és személyre szabott étkezési tervet biztosított, amely támogatta az edzéseimet. Mindössze két hónap alatt 5 kg izmot szedtem fel, és az energiaszintem az egekbe szökött. A PerfectPlate megszüntette a találgatást az étrendemben, és hatékonyan segített elérni a céljaimat."
        },
        review_three: {
            en: "Maintaining my ideal weight has always been a balancing act. Thanks to PerfectPlate, I now have a reliable partner in my health journey. The personalized nutrition plans keep me on track, ensuring I get the right balance of calories and nutrients. I love how the meal plans are customized to my tastes and dietary restrictions. It’s been six months, and I’ve effortlessly kept my weight stable while enjoying delicious meals every day. PerfectPlate is my go-to for staying fit and healthy!",
            cz: "Udržení si ideální váhy pro mě bylo vždy na hraně. Díky PerfectPlate mám nyní na své cestě ke zdraví spolehlivého partnera. Personalizované plány výživy mi pomáhají jít tím správným směrem a starají se o to, abych měla správnou rovnováhu kalorií a živin. Vyhovuje mi, že jsou plány jídel přizpůsobeny mým chutím a dietním omezením. Je to už šest měsíců, co si bez námahy udržuji stabilní váhu a zároveň si každý den vychutnávám vynikající jídla. PerfectPlate je můj věrný partner pro udržení kondice a zdraví!",
            hu: "Az ideális testsúlyom megtartása mindig egyensúlyozás volt. A PerfectPlate-nek köszönhetően most már megbízható partnerem van az egészségügyi utamon. A személyre szabott táplálkozási tervek a helyes úton tartanak, biztosítva a megfelelő kalória- és tápanyag-egyensúlyt. Imádom, hogy az étkezési tervek az ízlésemhez és diétás korlátaimhoz vannak igazítva. Már hat hónapja tart, és erőfeszítés nélkül tartom stabilan a súlyomat, miközben minden nap finom ételeket élvezhetek. A PerfectPlate az én megoldásom a fitt és egészséges élethez!"
        },
        popular: {
            en: "Most popular",
            cz: "Nejoblíbenější",
            hu: "Legnépszerűbb"
        },
        your_weight: {
            en: "Your Weight",
            cz: "Vaše váha",
            hu: "A testsúlyod"
        },
        g: {
            en: "Goal",
            cz: "Cíl",
            hu: "Cél"
        },
        oops: {
            en: "Oops, it looks like our servers are currently overloaded. We apologize for the inconvenience. Please try again later.",
            cz: "Jejda, zdá se, že naše servery jsou momentálně přetížené. Omlouváme se za nepříjemnosti. Zkuste to znovu později.",
            hu: "Hoppá, úgy tűnik, hogy a szervereink jelenleg túlterheltek. Elnézést kérünk a kellemetlenségért. Kérjük, próbáld meg később."
        }
    }
}
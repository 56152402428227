<template>
    <MyHeader userNick="Anonimus" />
    <MyNavigation :navs="navs" />
    <MyTest nextPage="Landing" :lang="lang" :title="locale.title_q6[this.lang]" :description="locale.description_q6[this.lang]">
        <TestPart :title="locale.subtitle_q6[this.lang]" :lang="lang" :locale="locale" :showButton="true" @onEdit="onEdit" v-if="!isEditable">
            <StyledInfo :lang="lang" :cal="calories" :fat="fat" :carb="carb" :protein="protein" />
        </TestPart>
        
        <TestPart :title="locale.title_q6[this.lang]" v-if="isEditable">
            <TestForm>
                <TestRow :title="locale.hint_q6[this.lang]">
                    <MyInput style="width: 100%;" :value="locale.subtitle_q6[this.lang]" />
                </TestRow>
                <TestRow :title="locale.calories_q6[this.lang]" style="border: none !important;">
                    <MyInput type="number" @onChange="setCal" :value="calories" />
                </TestRow>
                <MySuggest :lang="lang" :cal="calories" :fat="fat" :carb="carb" :protein="protein" />
            </TestForm>
        </TestPart>

        <TestPart :title="locale.macros_q6[this.lang]" :description="locale.select_q6[this.lang]" v-if="isEditable">
            <TestForm>
                <TestForm>
                    <TestRow :title="locale.carb_q6[this.lang]" style="border: none !important;">
                        <div class="flex">
                            <MyInput type="number" addon="to" @onChange="setCarb" :max="maxCarb" :value="carb" />
                            <MyInput type="number" addon="g" @onChange="setCarbMax" :max="maxCarb" :value="maxVCarb" />
                        </div>
                    </TestRow>
                    <MySlider @onMin="onMinCarb" @onMax="onMaxCarb" :min="minCarb" :max="maxCarb" :minV="carb" :maxV="maxVCarb" color="hsl(45, 97%, 48%)" />
                </TestForm>
                <TestForm>
                    <TestRow :title="locale.fat_q6[this.lang]" style="border: none !important;">
                        <div class="flex">
                            <MyInput type="number" addon="to" @onChange="setFat" :max="maxFat" :value="fat" />
                            <MyInput type="number" addon="g" @onChange="setFatMax" :max="maxFat" :value="maxVFat" />
                        </div>
                    </TestRow>
                    <MySlider @onMin="onMinFat" @onMax="onMaxFat" :min="minFat" :max="maxFat" :minV="fat" :maxV="maxVFat" color="hsl(185, 85%, 43.5%)" />
                </TestForm>
                <TestForm>
                    <TestRow :title="locale.protein_q6[this.lang]" style="border: none !important;">
                        <div class="flex">
                            <MyInput type="number" addon="to" @onChange="setProtein" :max="maxProtein" :value="protein" />
                            <MyInput type="number" addon="g" @onChange="setProteinMax" :max="maxProtein" :value="maxVProtein" />
                        </div>
                    </TestRow>
                    <MySlider @onMin="onMinProtein" @onMax="onMaxProtein" :min="minProtein" :max="maxProtein" :minV="protein" :maxV="maxVProtein" color="hsl(260, 100%, 73%)" />
                </TestForm>
            </TestForm>
        </TestPart>

        <TestPart :title="locale.micro_q6[this.lang]" v-if="isEditable">
            <TestForm>
                <TestRow :title="locale.mf_q6[this.lang]">
                    <MyInput addon="g" value="25" />
                </TestRow>
                <TestRow :title="locale.ld_q6[this.lang]">
                    <MySwitcher @onSwitch="onSodium" />
                </TestRow>
                <TestRow :title="locale.ms_q6[this.lang]" v-if="isSodium">
                    <MyInput addon="mg" value="2000" />
                </TestRow>
                <TestRow :title="locale.dc_q6[this.lang]">
                    <MySwitcher @onSwitch="onCholeo" />
                </TestRow>
                <TestRow :title="locale.mc_q6[this.lang]" v-if="isCholeo">
                    <MyInput addon="mg" value="300" />
                </TestRow>
            </TestForm>
        </TestPart>
    </MyTest>
</template>

<script>
import data from '@/locale';
import MyTest from '@/components/Test.vue'
import MyHeader from '@/components/Header.vue';
import MyNavigation from '@/components/Navigation.vue';

import TestRow from '@/components/TestRow.vue';
import TestPart from '@/components/TestPart.vue';
import TestForm from '@/components/TestForm.vue';
import MyInput from '@/components/Input.vue';
import MySuggest from '@/components/Suggest.vue';
import MySwitcher from '@/components/Switcher.vue';
import StyledInfo from '@/components/StyledInfo.vue';
import MySlider from '@/components/Slider.vue';

export default {
    name: 'EditPage',
    components: {
      MyTest,
      MyHeader,
      MyNavigation,
      TestRow,
      TestForm,
      TestPart,
      MyInput,
      MySuggest,
      MySwitcher,
      StyledInfo,
      MySlider
    },
    methods: {
        getImageUrl(path) {
            let images = require.context('@/assets/', false, /\.png$|\.jpg$/);

            return images(path);
        },
        setCal(val) {
            this.calories = parseInt(val);
        },
        setCarb(val) {
            this.carb = parseInt(val);
            this.carb = this.carb > this.maxCarb ? this.maxCarb : this.carb;
        },
        setCarbMax(val) {
            this.maxVCarb = parseInt(val);
            this.maxVCarb = this.maxVCarb > this.maxCarb ? this.maxCarb : this.maxVCarb;
        },
        setFat(val) {
            this.fat = parseInt(val);
            this.fat = this.fat > this.maxFat ? this.maxFat : this.fat;
        },
        setFatMax(val) {
            this.maxVFat = parseInt(val);
            this.maxVFat = this.maxVFat > this.maxFat ? this.maxFat : this.maxVFat;
        },
        setProtein(val) {
            this.protein = parseInt(val);
            this.protein = this.protein > this.maxProtein ? this.maxProtein : this.protein;
        },
        setProteinMax(val) {
            this.maxVProtein = parseInt(val);
            this.maxVProtein = this.maxVProtein > this.maxProtein ? this.maxProtein : this.maxVProtein;
        },
        onMode(sel) {
            this.selectedMode = sel;
        },
        onMinFat(val) {
            this.fat = val;
        },
        onMaxFat(val) {
            this.maxVFat = val;
        },
        onMinCarb(val) {
            this.carb = val;
        },
        onMaxCarb(val) {
            this.maxVCarb = val;
        },
        onMinProtein(val) {
            this.protein = val;
        },
        onMaxProtein(val) {
            this.maxVProtein = val;
        },
        onEdit() {
            this.isEditable = !this.isEditable;
        },
        onCholeo(is_selected) {
            this.isCholeo = is_selected
        },
        onSodium(is_selected) {
            this.isSodium = is_selected
        },
        loadData() {
            this.cm = localStorage.getItem('cm') ? localStorage.getItem('cm') : this.cm;
            this.kg = localStorage.getItem('kg') ? localStorage.getItem('kg') : this.kg;
            // this.age = localStorage.getItem('age') ? localStorage.getItem('age') : this.age;
            this.sex = localStorage.getItem('sex') ? localStorage.getItem('sex') : this.sex;
            this.activity = localStorage.getItem('activity') ? localStorage.getItem('activity') : this.activity;
        },
        calcData() {
            let m = this.activity in this.multi ? this.multi[this.activity] : 1.2;

            this.calories = ((10 * this.kg) + (6.25 * this.cm)) - (5 * 30);
            this.calories = this.sex == 0 ? this.calories + 5 : this.calories - 161;
            this.calories *= m;

            this.calories = parseInt(this.calories);
            this.fat = parseInt(this.calories * 0.04);
            this.carb = parseInt(this.calories * 0.1);
            this.protein = parseInt(this.calories * 0.07);

            this.maxFat = parseInt(this.calories * 0.15);
            this.maxCarb = parseInt(this.calories * 0.25);
            this.maxProtein = parseInt(this.calories * 0.25);

            this.maxVFat = parseInt(this.fat * 1.25);
            this.maxVCarb = parseInt(this.carb * 1.25);
            this.maxVProtein = parseInt(this.protein * 1.25);
        }
    },
    created() {
        this.lang = !this.$route.params.locale || !this.supportedLangs.includes(this.$route.params.locale) ? "en" : this.$route.params.locale

        this.loadData();
        this.calcData();
    },
    data() {
      return {
        locale: data.data,
        lang: "en",
        supportedLangs: ["en", "cz", "hu"],
        navs: ["nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-item-past", "nav-item nav-parrot"],
        multi: {
            0: 1.2,
            1: 1.375,
            2: 1.55,
            3: 1.725,
            5: 1.9
        },
        filters: ["Within Range", "As a percentage"],
        isEditable: false,
        isCholeo: false,
        isSodium: false,
        age: 20,
        cm: 180,
        kg: 70,
        sex: 0,
        activity: 0,
        calories: 0,
        fat: 0,
        carb: 0,
        protein: 0,
        minFat: 0,
        maxFat: 0,
        maxVFat: 0,
        minCarb: 0,
        maxCarb: 0,
        maxVCarb: 0,
        minProtein: 0,
        maxProtein: 0,
        maxVProtein: 0,
        selectedMode: 0
      }
    }
}
</script>

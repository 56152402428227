<template>
  <div @click="onClickItem" :class="classes" :data-title="title">
    <MyRatio />

    <div class="item-logo item-logo--desc">
      <img :src="img" alt="#" class="item-image" />
    </div>

    <div class="item-info">
      <div class="item-wrap-title">
        <h5 class="item-title">{{ title }}</h5>
        <div class="item-logo item-logo--mobil">
          <img :src="img" alt="#" class="item-image" />
        </div>
      </div>
      <p class="item-content">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import MyRatio from "@/components/Ratio.vue";

export default {
  name: "ItemRatio",
  components: {
    MyRatio,
  },
  props: {
    img: String,
    title: String,
    classes: String,
    description: String,
  },
};
</script>

<style scoped>
.item-v1 {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;

  padding: 1rem 2rem;
  border: 2px solid var(--secondary);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;
}

.item-v1:hover,
.item-v1-click {
  border-color: var(--active);
  transition: 0.5s all ease-in-out;
}

.item-logo,
.item-image {
  width: 4rem;
  height: 4rem;
}

.item-info {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.item-title,
.item-content {
  width: 100%;
  text-align: left;
  font-size: 1rem;
}

.item-title {
  font-weight: 700;
}

.item-content {
  font-weight: 500;
}

.item-logo--mobil {
  display: none;
}

@media screen and (max-width: 1024px) {
  .test-input {
    max-width: 4rem;
  }
}

@media screen and (max-width: 564px) {
  .item-info {
    align-items: flex-start;
  }
  .item-wrap-title {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .item-logo--desc {
    display: none;
  }

  .item-logo--mobil {
    display: flex;
  }

  .item-logo {
    width: 2rem;
    height: 2rem;
  }

  .item-image {
    width: 2rem;
    height: 2rem;
  }
  .test-footer {
    gap: 1rem;
  }

  .test-footer__link {
    width: 100%;
  }
}
</style>
